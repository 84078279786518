import React from "react"

const Priority = ({ number, title, copy }) => {
    const isCopy = copy
    return (
        <div className="priority">
            <div className="priority__wrapper">
                <span className="priority__number small-text">0{number}</span>
                <h2 className="priority__title">{title}</h2>
                <span className="priority__underline"></span>
                {isCopy && (
                    <p
                        className="priority__copy"
                        dangerouslySetInnerHTML={{ __html: copy }}
                    ></p>
                )}
            </div>
        </div>
    )
}

export default Priority
