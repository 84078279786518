import React from "react"
import PublicIcon from "../assets/svg/filter-icon--public.svg"
import MetroIcon from "../assets/svg/filter-icon--metro.svg"
import DevelopmentIcon from "../assets/svg/filter-icon--development.svg"
import AllIcon from "../assets/svg/filter-icon--all.svg"

//Acceptable Modifiers: primary, special

const ImpactFilters = ({ filterAction }) => {
    const wrapperRef = React.createRef()

    const switchClass = event => {
        const btns = [
            ...wrapperRef.current.querySelectorAll(".impact-filters__icon"),
        ]
        btns.forEach(btn => btn.classList.remove("active"))
        event.target.classList.add("active")
    }

    const onClick = event => {
        switchClass(event)
        filterAction(event)
    }

    return (
        <section id="impactFilters" className="impact-filters">
            <p className="small-text">Filter Areas of Impact</p>
            <div className="impact-filters__wrapper" ref={wrapperRef}>
                <button
                    className="impact-filters__icon"
                    onClick={onClick}
                    data-filter=".vibrant-inclusive-public-space"
                >
                    <PublicIcon />
                    <p>Vibrant + Inclusive</p>
                </button>
                <button
                    className="impact-filters__icon"
                    onClick={onClick}
                    data-filter=".smart-connected-metro"
                >
                    <MetroIcon />
                    <p>Smart + Connected</p>
                </button>
                <button
                    className="impact-filters__icon"
                    onClick={onClick}
                    data-filter=".equitable-sustainable-developments"
                >
                    <DevelopmentIcon />
                    <p>Equitable + Sustainable</p>
                </button>
                <button
                    className="impact-filters__icon active"
                    onClick={onClick}
                    data-filter="*"
                >
                    <AllIcon />
                    <p>All</p>
                </button>
            </div>
        </section>
    )
}

export default ImpactFilters
