import React, { Component } from "react"
import ImpactFilters from "../impactFilters"
import PostFilters from "../postFilters"
import PostCardGroup from "../../components/postCardGroup"
import Plus from "../../assets/svg/plus.svg"
import Minus from "../../assets/svg/minus.svg"
var Isotope = require("isotope-layout")
var chunk = require("lodash.chunk")

class PostsContainer extends Component {
    state = {
        page: 1,
        chunksPerPage: 2,
        allLoaded: false,
    }

    isotopeRef = React.createRef()
    filtervalue = `*`

    renderPosts = () => {
        let { posts } = this.props

        let { page, chunksPerPage } = this.state
        let chunks = chunk(Array.from(posts), 2)
        let paginated = Array.from(chunks).splice(0, page * chunksPerPage)

        return paginated.map((group, index) => (
            <PostCardGroup posts={group} key={index + 63} />
        ))
    }

    onLoad = () => {
        let { page, chunksPerPage } = this.state
        let { posts } = this.props
        let allLoaded = (page + 1) * chunksPerPage >= posts.length / 2
        this.setState({
            page: this.state.page + 1,
            allLoaded: allLoaded,
        })
    }

    loadLess = () => {
        this.setState({
            page: 1,
            allLoaded: false,
        })
        window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: this.mainContainer.current.offsetTop - 200,
        })
    }

    filterAction = event => {
        this.iso.arrange({ filter: event.target.dataset.filter })
        this.filterValue = event.target.dataset.filter
    }

    componentDidMount() {
        this.iso = new Isotope(this.isotopeRef.current, {
            percentPosition: true,
            itemSelector: ".card",
            masonry: {
                columnWidth: ".card",
                gutter: 56,
            },
        })
        this.mainContainer = React.createRef()
    }

    componentDidUpdate() {
        this.iso.reloadItems()
        this.iso.arrange({ filter: this.filterValue })
    }

    componentWillUnmount() {
        this.iso.destroy()
    }

    render() {
        return (
            <>
                {this.props.isProject && (
                    <ImpactFilters filterAction={this.filterAction} />
                )}
                {this.props.isPosts && (
                    <PostFilters filterAction={this.filterAction} />
                )}
                <section
                    className="projects-container"
                    ref={this.mainContainer}
                >
                    <div
                        className="projects-container__wrapper"
                        ref={this.isotopeRef}
                    >
                        {this.renderPosts()}
                    </div>
                    {this.props.posts.length >=
                        this.state.chunksPerPage * 2 + 1 && (
                        <div className="projects-container__load">
                            <button
                                className="load-more-posts"
                                disabled={!this.state.allLoaded}
                                onClick={this.loadLess}
                            >
                                <span className="load-text">Load Less</span>
                                <span className="load-svg">
                                    <Minus />
                                </span>
                            </button>
                            <button
                                className="load-more-posts"
                                disabled={this.state.allLoaded}
                                onClick={this.onLoad}
                            >
                                <span className="load-text">
                                    {this.state.allLoaded
                                        ? "All Loaded"
                                        : "Load More"}
                                </span>
                                <span className="load-svg">
                                    <Plus />
                                </span>
                            </button>
                        </div>
                    )}
                </section>
            </>
        )
    }
}

export default PostsContainer
