import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollableSection from "../components/scrollable-section"
import Img from "gatsby-image"
import AddressIcon from "../assets/svg/address.svg"
import PhoneIcon from "../assets/svg/phone.svg"
import EmailIcon from "../assets/svg/email.svg"

const CONTACT_PAGE_QUERY = graphql`
    query ContactQuery {
        wpgraphql {
            pageBy(uri: "contact-us") {
                Options {
                    address
                    email
                    phone
                    contactMessage
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const mapsSelector = e => {
    e.preventDefault()
    if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf("iPhone") !== -1 ||
        navigator.platform.indexOf("iPad") !== -1 ||
        navigator.platform.indexOf("iPod") !== -1
    )
        window.open(
            "maps://maps.google.com/maps?daddr=41.2535442,-95.9307597&amp;ll="
        )
    /* else use Google */ else
        window.open(
            "https://maps.google.com/maps?daddr=41.2535442,-95.9307597&amp;ll="
        )
}

const ContactUs = () => {
    const {
        wpgraphql: {
            pageBy: { Options },
        },
        placeholderImage,
    } = useStaticQuery(CONTACT_PAGE_QUERY)

    return (
        <>
            <ScrollableSection classNames="contact-hero">
                <Img
                    fluid={placeholderImage.childImageSharp.fluid}
                    style={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        width: `100%`,
                        height: `100%`,
                        opacity: `0.04`,
                        zIndex: -1,
                    }}
                />
                <div className="contact-hero__wrapper">
                    <div className="contact-hero__content">
                        <h1>Let's get in touch</h1>
                        <div className="contact-hero__content-wrapper">
                            <a
                                className="contact-item"
                                onClick={e => mapsSelector(e)}
                            >
                                <AddressIcon />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: Options.address,
                                    }}
                                ></div>
                            </a>
                            <a
                                href={`tel:${Options.phone}`}
                                className="contact-item"
                            >
                                <PhoneIcon />
                                <p>{Options.phone}</p>
                            </a>
                            <a
                                href={`mailto:${Options.email}`}
                                className="contact-item"
                            >
                                <EmailIcon />
                                <p>{Options.email}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </ScrollableSection>
            <ScrollableSection classNames="contact-form-container">
                <p
                    dangerouslySetInnerHTML={{ __html: Options.contactMessage }}
                ></p>
                <form
                    className="contact-form"
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    data-netlify-recaptcha="true"
                >
                    <input type="text" name="name" placeholder="Name" />
                    <input type="email" name="email" placeholder="Email" />
                    <textarea
                        name="message"
                        placeholder="Tell us a little more."
                        cols="30"
                        rows="20"
                    ></textarea>
                    <div className="contact-btn">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </ScrollableSection>
        </>
    )
}

export default ContactUs
