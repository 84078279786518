import { StaticQuery, graphql } from "gatsby"
import React, { Component } from "react"
import { TweenMax, TimelineMax, Expo } from "gsap/TweenMax"
import ScrollableSection from "../scrollable-section"
import TimelineSlide from "./timelineSlide"
import TimelineNumber from "./timelineNumber"
import Arrow from "../../assets/svg/arrow-right.svg"

const TIMELINE_QUERY = graphql`
    query TimelineQuery {
        wpgraphql {
            timelineEvents {
                nodes {
                    timelineEvent {
                        headline
                        copy
                        year
                        mainImage {
                            sourceUrl
                            srcSet
                        }
                        secondaryImage {
                            sourceUrl
                            srcSet
                        }
                    }
                }
            }
        }
    }
`

class Timeline extends Component {
    constructor(props) {
        super(props)
        this.slides = []
        this.slideWrapper = React.createRef()
        this.elementsWrapper = React.createRef()
        this.state = {
            current: 0,
            previous: null,
            isAnimating: false,
        }
    }

    handlePreviousClick = () => {
        if (!this.state.isAnimating) {
            const next = this.state.current - 1
            const previous = this.state.current

            this.setState({
                current:
                    next < 0
                        ? this.props.data.wpgraphql.timelineEvents.nodes
                              .length - 1
                        : next,
            })

            setTimeout(() => {
                this.slideAnimationTL(this.state.current, previous)
            }, 100)
            this.setState({ isAnimating: true })
        }
    }

    handleNextClick = () => {
        if (!this.state.isAnimating) {
            const next = this.state.current + 1
            const previous = this.state.current

            this.setState({
                current:
                    next ===
                    this.props.data.wpgraphql.timelineEvents.nodes.length
                        ? 0
                        : next,
            })

            setTimeout(() => {
                this.slideAnimationTL(this.state.current, previous)
            }, 100)
            this.setState({ isAnimating: true })
        }
    }

    handleYearClick = event => {
        if (!this.state.isAnimating) {
            const next = parseInt(event.target.dataset.index)
            const previous = this.state.current

            this.setState({
                current: next,
            })

            setTimeout(() => {
                this.slideAnimationTL(this.state.current, previous)
            }, 100)
            this.setState({ isAnimating: true })
        }
    }

    mainImageAnimationTL = (slideInMainImage, slideOutMainImage) => {
        const tl = new TimelineMax()
        tl.to(
            [slideOutMainImage],
            1.1,
            { xPercent: 100, ease: Expo.easeInOut },
            0.1
        ).fromTo(
            [slideInMainImage],
            1.1,
            { xPercent: -115, scale: 1.3 },
            { xPercent: 0, scale: 1, ease: Expo.easeInOut },
            0.1
        )
        return tl
    }

    secondaryImageAnimationTL = (
        slideInSecondaryImage,
        slideOutSecondaryImage
    ) => {
        const tl = new TimelineMax()
        tl.to(
            [slideOutSecondaryImage],
            1.1,
            { xPercent: 100, ease: Expo.easeInOut },
            0.1
        ).fromTo(
            [slideInSecondaryImage],
            1.1,
            { xPercent: -115, scale: 1.3 },
            { xPercent: 0, scale: 1, ease: Expo.easeInOut },
            0.1
        )
        return tl
    }

    contentAnimationTL = (
        slideInContent,
        slideOutContent,
        slideInYear,
        slideOutYear
    ) => {
        const tl = new TimelineMax()

        tl.staggerFromTo(
            slideOutContent,
            0.8,
            { y: 0 },
            { y: -35, autoAlpha: 0, ease: Expo.easeInOut },
            0.1
        )
            .staggerFromTo(
                slideInContent,
                0.8,
                { y: 40, autoAlpha: 0 },
                { autoAlpha: 1, y: 0, ease: Expo.easeOut },
                0.1
            )
            .to(slideOutYear, 0.5, { opacity: 0 }, 0)
            .fromTo(
                slideInYear,
                0.9,
                { opacity: 0 },
                { opacity: 0.05, ease: Expo.easeOut },
                0.7
            )

        return tl
    }

    slideAnimationTL = (current, previous) => {
        const masterTL = new TimelineMax({
            onComplete: () => {
                TweenMax.set(slideOut, { autoAlpha: 0 })
                TweenMax.set(
                    [
                        slideOutMainImage.parentElement,
                        slideOutSecondaryImage.parentElement,
                    ],
                    { boxShadow: "4px 6px 31px 5px rgba(0,0,0,0.20);" }
                )
                this.setState({
                    isAnimating: false,
                })
            },
        })

        //Slide Out Items
        const slideOut = this.slides[previous]
        const slideOutMainImage = slideOut.querySelector(
            ".timeline-slide__image--main img"
        )
        const slideOutSecondaryImage = slideOut.querySelector(
            ".timeline-slide__image--secondary img"
        )
        const slideOutContent = slideOut.querySelectorAll(
            ".timeline-slide__headline, .timeline-slide__copy p"
        )
        const slideOutYear = slideOut.querySelector(
            ".timeline-slide .vertical-text"
        )

        //Slide In Items
        const slideIn = this.slides[current]
        const slideInMainImage = slideIn.querySelector(
            ".timeline-slide__image--main img"
        )
        const slideInSecondaryImage = slideIn.querySelector(
            ".timeline-slide__image--secondary img"
        )
        const slideInContent = slideIn.querySelectorAll(
            ".timeline-slide__headline, .timeline-slide__copy p"
        )
        const slideInYear = slideIn.querySelector(
            ".timeline-slide .vertical-text"
        )

        masterTL
            .set(slideIn, { autoAlpha: 1 })
            .set(
                [
                    slideOutMainImage.parentElement,
                    slideOutSecondaryImage.parentElement,
                ],
                { boxShadow: "none" }
            )
            .add(
                this.mainImageAnimationTL(slideInMainImage, slideOutMainImage),
                0
            )
            .add(
                this.contentAnimationTL(
                    slideInContent,
                    slideOutContent,
                    slideInYear,
                    slideOutYear
                ),
                0
            )
            .add(
                this.secondaryImageAnimationTL(
                    slideInSecondaryImage,
                    slideOutSecondaryImage
                ),
                0.1
            )

        return masterTL
    }

    // use React lifecycle methods as needed

    componentDidMount() {
        this.slides = [
            ...this.slideWrapper.current.querySelectorAll(".timeline-slide"),
        ]
        this.slides.forEach(slide => {
            if (!slide.classList.contains("timeline-slide--current"))
                TweenMax.set(slide, { autoAlpha: 0 })
        })
        // this.spanWidth =
        //     this.elementsWrapper.current
        //         .querySelector(".timeline-dates__year")
        //         .getBoundingClientRect().width /
        //     2 /
        //     this.elementsWrapper.current.getBoundingClientRect().width
        this.spanWidth =
            this.elementsWrapper.current
                .querySelector(".timeline-dates__year")
                .getBoundingClientRect().width / 2

        this.elementsWrapper.current.querySelector(
            ".timeline-indicator__line"
        ).style.marginLeft = `-${this.spanWidth}px`
    }

    render() {
        const { nodes: slides } = this.props.data.wpgraphql.timelineEvents
        const { current } = this.state
        const underlineTransform = {
            transform: `scalex( ${(current + 1) / slides.length} )`,
        }
        return (
            <ScrollableSection classNames={`timeline`}>
                <div
                    className="timeline__wrapper timeline__wrapper--elements"
                    ref={this.elementsWrapper}
                >
                    <span className="timeline__bg"></span>
                    <div className="timeline-nav">
                        <div className="timeline-nav__wrapper">
                            <button
                                className="timeline-nav__btn timeline-nav__btn--prev"
                                onClick={this.handlePreviousClick}
                            >
                                <Arrow />
                            </button>
                            <button
                                className="timeline-nav__btn timeline-nav__btn--next"
                                onClick={this.handleNextClick}
                            >
                                <Arrow />
                            </button>
                        </div>
                    </div>
                    <div className="timeline-progress">
                        <div
                            className="timeline-dates"
                            ref={this.timelineDateRef}
                        >
                            {slides.map(({ timelineEvent: slide }, index) => (
                                <TimelineNumber
                                    key={index + 193}
                                    index={index}
                                    current={current}
                                    year={slide.year}
                                    handleClick={this.handleYearClick}
                                />
                            ))}
                        </div>
                        <div className="timeline-indicator">
                            <span
                                className="timeline-indicator__line"
                                style={underlineTransform}
                            ></span>
                        </div>
                    </div>
                </div>
                <div
                    className="timeline__wrapper timeline__wrapper--slides"
                    ref={this.slideWrapper}
                >
                    {slides.map(({ timelineEvent: slide }, index) => (
                        <TimelineSlide
                            key={slide.headline}
                            slide={slide}
                            index={index}
                            current={current}
                        />
                    ))}
                </div>
            </ScrollableSection>
        )
    }
}

export default props => (
    <StaticQuery
        query={TIMELINE_QUERY}
        render={data => <Timeline data={data} {...props} />}
    />
)
