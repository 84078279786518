import React from "react"
import ScrollableSection from "./scrollable-section"
import Img from "gatsby-image"
// import { Controller, Scene } from "react-scrollmagic"
import { useInView } from "react-intersection-observer"

const WhySection = props => {
    const { headline, copy, backgroundText, quote, image } = props.why
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    })
    const addedClass = inView ? `is-inview` : `no`

    return (
        <ScrollableSection classNames="matters">
            <div className="matters__wrapper">
                <div className="matters__content">
                    <h2>{headline}</h2>
                    <p>{copy}</p>
                </div>
                <figure className="matters__image">
                    <img
                        src={image.sourceUrl}
                        srcSet={image.srcSet}
                        sizes="60vw"
                        alt={headline}
                    />
                </figure>

                <div
                    className={`matters__quote ${addedClass}`}
                    dangerouslySetInnerHTML={{ __html: quote }}
                    ref={ref}
                ></div>
                <p className="vertical-text">{backgroundText}</p>
                <Img
                    fluid={props.bgImage}
                    style={{
                        position: `absolute`,
                        bottom: `25%`,
                        left: 0,
                        width: `100%`,
                        height: `400px`,
                        background: `#F4F4F4`,
                        zIndex: -1,
                    }}
                />
            </div>
        </ScrollableSection>
    )
}

export default WhySection
