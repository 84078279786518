import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InteriorHero from "../components/interior-hero"
import ScrollableSection from "../components/scrollable-section"
import HomeNews from "../components/home-news/homeNews"
import Priority from "../components/our-priorities/priority"
import Button from "../components/button"
import QuoteBlock from "../components/quote-block"
import Arrow from "../assets/svg/arrow-right.svg"

const INVOLVED_PAGE_QUERY = graphql`
    query InvolvedQuery {
        wpgraphql {
            pageBy(uri: "get-involved") {
                interiorHero {
                    interiorHero {
                        copy
                        headline
                        subHeadline
                        image {
                            srcSet
                            sourceUrl
                        }
                    }
                }
                quoteBlock {
                    quote
                    reference
                }
            }
            engagements {
                nodes {
                    id
                    title
                    content
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const GetInvolvedPage = () => {
    const {
        wpgraphql: {
            pageBy: {
                interiorHero: {
                    interiorHero: { copy, headline, subHeadline, image },
                },
                quoteBlock,
            },
            engagements: { nodes: engagements },
        },
        placeholderImage,
    } = useStaticQuery(INVOLVED_PAGE_QUERY)

    return (
        <>
            <InteriorHero
                bgImage={placeholderImage.childImageSharp.fluid}
                subHeadline={subHeadline}
                headline={headline}
                image={image}
                copy={copy}
            />
            <HomeNews bgText="Connect" modifier="involved" />
            <ScrollableSection classNames="priorities priorities--involved">
                <h4 className="priorities__heading">Engage in our work</h4>
                <div className="priorities__wrapper">
                    {engagements.map((engagement, index) => (
                        <Priority
                            key={engagement.id}
                            number={index + 1}
                            title={engagement.title}
                            copy={engagement.content}
                        />
                    ))}
                </div>
                <div
                    className="involved-btn-container"
                    style={{
                        display: `flex`,
                        justifyContent: `center`,
                        marginTop: `132px`,
                    }}
                >
                    <Button
                        modifier="primary"
                        text="contact us for more info"
                        link={`/contact-us`}
                    />
                </div>
                <p className="vertical-text">create</p>
            </ScrollableSection>
            <ScrollableSection classNames="priorities priorities--involved">
                <h4 className="priorities__heading">Support Our Mission</h4>
                <div className="priorities__wrapper">
                    <div className="priority">
                        <div className="priority__wrapper">
                            <span className="priority__number small-text">
                                01
                            </span>
                            <h2 className="priority__title">Donate</h2>
                            <span
                                className="priority__underline"
                                style={{ marginBottom: `24px` }}
                            ></span>
                            <a
                                className="btn-primary"
                                rel="noopener noreferrer"
                                href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7SB6UYL36JNLE&source=url"
                                target="_blank"
                            >
                                contribute now
                                <Arrow width={30} />
                            </a>
                        </div>
                    </div>
                    <div className="priority">
                        <div className="priority__wrapper">
                            <span className="priority__number small-text">
                                02
                            </span>
                            <h2 className="priority__title">In-Kind Support</h2>
                            <span
                                className="priority__underline"
                                style={{ marginBottom: `24px` }}
                            ></span>
                            <Button
                                modifier="primary"
                                text="contact us"
                                link={`/contact-us`}
                            />
                        </div>
                    </div>
                </div>
            </ScrollableSection>
            <QuoteBlock
                quote={quoteBlock.quote}
                name={quoteBlock.reference}
                bgImage={placeholderImage.childImageSharp.fluid}
                bgColor={`gray`}
            />
        </>
    )
}

export default GetInvolvedPage
