import React from "react"
import Card from "./card"

//Acceptable Modifiers: primary, special

const PostCardGroup = props => {
    const { posts } = props

    return (
        <>
            {posts.map(post => (
                <Card
                    key={post.id}
                    date={post.date}
                    category={post.termSlugs}
                    image={post.featuredImage}
                    title={post.title}
                    excerpt={post.excerpt}
                    slug={post.slug}
                    tags={post.tags.nodes}
                />
            ))}
        </>
    )
}

export default PostCardGroup
