import React from "react"
import ScrollableSection from "../scrollable-section"
import Img from "gatsby-image"

const InteriorHero = ({ subHeadline, headline, image, copy, bgImage }) => {
    return (
        <ScrollableSection classNames="int-hero">
            <Img
                fluid={bgImage}
                style={{
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    width: `100%`,
                    height: `100%`,
                    opacity: `0.04`,
                    zIndex: -1,
                }}
            />
            <div className="int-hero__wrapper">
                <div className="int-hero__image">
                    <img
                        src={image.sourceUrl}
                        srcSet={image.srcSet}
                        alt={headline}
                    />
                </div>
                <div className="int-hero__content">
                    <div className="int-hero__content-wrapper">
                        <p>{subHeadline}</p>
                        <h1>{headline}</h1>
                    </div>
                </div>
            </div>
            <div
                className="int-hero__copy"
                dangerouslySetInnerHTML={{ __html: copy }}
            ></div>
        </ScrollableSection>
    )
}

export default InteriorHero
