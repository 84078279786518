import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import FeaturedPost from "../components/featuredPost"
import PostsContainer from "../components/posts-container"

const NEWS_PAGE_QUERY = graphql`
    query NewsQuery {
        wpgraphql {
            pages(where: { title: "News + Events" }) {
                nodes {
                    NewsInfo {
                        headline
                        subHeadline
                    }
                }
            }
            posts {
                nodes {
                    id
                    title
                    slug
                    excerpt
                    date
                    featuredImage {
                        node {
                            sourceUrl
                            srcSet
                        }
                    }
                    terms(where: { taxonomies: TAG }) {
                        nodes {
                            slug
                        }
                    }
                    tags {
                        nodes {
                            slug
                            name
                        }
                    }
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const NewsEvents = () => {
    const {
        wpgraphql: {
            pages: { nodes },
            posts: { nodes: posts },
        },
        placeholderImage,
    } = useStaticQuery(NEWS_PAGE_QUERY)

    const headline = nodes[0].NewsInfo.headline
    const subHeadline = nodes[0].NewsInfo.subHeadline

    return (
        <>
            <section className="news-hero">
                <div className="news-hero__wrapper">
                    <Img
                        fluid={placeholderImage.childImageSharp.fluid}
                        style={{
                            position: `absolute`,
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`,
                            opacity: `0.04`,
                            zIndex: -1,
                        }}
                    />
                    <h1>{headline}</h1>
                    <p>{subHeadline}</p>
                </div>
            </section>
            <FeaturedPost />
            <PostsContainer posts={posts} isPosts={true} />
        </>
    )
}

export default NewsEvents
