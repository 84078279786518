import React from "react"
import { useStaticQuery } from "gatsby"
import ScrollableSection from "../scrollable-section"
import Priority from "./priority"
import { graphql } from "gatsby"

const PRIORITY_QUERY = graphql`
    query PriorityQuery {
        wpgraphql {
            priorities {
                nodes {
                    content
                    title
                    id
                }
            }
        }
    }
`

const Priorities = () => {
    const {
        wpgraphql: {
            priorities: { nodes: priorities },
        },
    } = useStaticQuery(PRIORITY_QUERY)

    return (
        <ScrollableSection classNames="priorities">
            <h4 className="priorities__heading">Our Priorities</h4>
            <div className="priorities__wrapper">
                {priorities.map((priority, index) => (
                    <Priority
                        key={priority.id}
                        number={index + 1}
                        title={priority.title}
                        copy={priority.content}
                    />
                ))}
            </div>
            <p className="vertical-text">priorities</p>
        </ScrollableSection>
    )
}

export default Priorities
