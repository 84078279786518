import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InteriorHero from "../components/interior-hero"
import ImpactSection from "../components/areaOfImpact"
import PostsContainer from "../components/posts-container"
// import ProjectMap from "../components/project-map/index"
import HomeNews from "../components/home-news/homeNews"

const AREAS_PAGE_QUERY = graphql`
    query AreasQuery {
        wpgraphql {
            pageBy(uri: "areas-of-impact") {
                interiorHero {
                    interiorHero {
                        copy
                        headline
                        subHeadline
                        image {
                            srcSet
                            sourceUrl
                        }
                    }
                }
            }
            areasOfImpact {
                nodes {
                    id
                    title
                    content
                    featuredImage {
                        node {
                            sourceUrl
                            srcSet
                        }
                    }
                }
            }
            projects(first: 60) {
                nodes {
                    id
                    title
                    slug
                    excerpt
                    date
                    featuredImage {
                        node {
                            srcSet
                            sourceUrl
                        }
                    }
                    terms(where: { taxonomies: TAG }) {
                        nodes {
                            slug
                        }
                    }
                    tags {
                        nodes {
                            slug
                            name
                        }
                    }
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const AreasOfImpact = () => {
    const {
        wpgraphql: {
            pageBy: {
                interiorHero: {
                    interiorHero: { copy, headline, subHeadline, image },
                },
            },
            areasOfImpact: { nodes: areas },
            projects: { nodes: projects },
        },
        placeholderImage,
    } = useStaticQuery(AREAS_PAGE_QUERY)
    console.log(projects)

    return (
        <>
            <InteriorHero
                bgImage={placeholderImage.childImageSharp.fluid}
                subHeadline={subHeadline}
                headline={headline}
                image={image}
                copy={copy}
            />
            {areas.map(area => (
                <ImpactSection key={area.id} area={area} />
            ))}
            <PostsContainer posts={projects} isProject={true} />
            {/* <ProjectMap /> */}
            <HomeNews />
        </>
    )
}

export default AreasOfImpact
