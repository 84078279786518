import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import GetInvolved from "../components/get-involved"
import AboutUs from "./aboutUs"
import Why from "./why"
import AreasOfImpact from "./areasOfImpact"
import NewsEvents from "../templates/newsEvents"
import GetInvolvedPage from "./getInvolved"
import ContactUs from "../templates/contactUs"

import BannerAffordable from "../components/flexible/bannerAffordable"
 

const components = {
    AboutUs: AboutUs,
    WhyUrbanDesignMatters: Why,
    AreasofImpact: AreasOfImpact,
    OptionsPage: Why,
    NewsEvents: NewsEvents,
    GetInvolved: GetInvolvedPage,
    ContactUs: ContactUs,
    BannerAffordable: BannerAffordable,
}

const renderPage = title => {
    // const camelCase = title.replace(/\s+/g, "")

    const camelCase = title.replace(/[^A-Z0-9]+/gi, "")

    const Component = components[camelCase]
    return <Component />
}

const Page = props => {
    const {
        data: {
            wpgraphql: { page },
        },
    } = props
    const { title, flexContent, slug, id } = page

    return (
        <>
            <Seo title={`${page.title}`} />
            { flexContent['flexibleContent'].length == 0 && <div className={`page page--${page.uri}`}>{renderPage(title)}</div>}
            {title !== `Get Involved` && flexContent['flexibleContent'].length == 0 && <GetInvolved />}
            { flexContent && flexContent.flexibleContent.map((layout, index) => {
                if(layout.fieldGroupName === "Page_Flexcontent_FlexibleContent_BannerAffordable") {
                    console.log('shoow');
                    return ( <BannerAffordable content={layout} key={index} index={index} id={slug} page={id} /> )
                }
            })
            }

        </>
    )
}

export default Page

export const pageQuery = graphql`
    query GET_PAGE($id: ID!) {
        wpgraphql {
            page(id: $id) {
                title
                content
                uri
                slug
                id
                flexContent {
                    flexibleContent {
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_BannerAffordable {
                        fieldGroupName
                      }
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_PartnersTab {
                        fieldGroupName
                        tabName
                      }
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_PurposeTab {
                        fieldGroupName
                        tabName
                      }
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_ProcessTab {
                        fieldGroupName
                        tabName
                      }
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_DesignTab {
                        fieldGroupName
                        tabName
                      }
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_PrototypesTab {
                        fieldGroupName
                        tabName
                      }
                      ... on WPGraphQL_Page_Flexcontent_FlexibleContent_Tabs {
                        fieldGroupName
                        themeColor
                      }
                    }
                  }
            }
        }
    }
`
