import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as moment from "moment"
import Button from "../components/button"
import ScrollableSection from "../components/scrollable-section"

const FEATURED_POST_QUERY = graphql`
    query FeaturedPostQuery {
        wpgraphql {
            posts(first: 1, where: { categoryId: 18 }) {
                nodes {
                    id
                    tags(first: 1) {
                        nodes {
                            name
                        }
                    }
                    date
                    title
                    excerpt
                    slug
                    featuredImage {
                        node {
                            srcSet
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
`

const FeaturedPost = () => {
    const {
        wpgraphql: {
            posts: { nodes },
        },
    } = useStaticQuery(FEATURED_POST_QUERY)

    const featured = nodes[0]

    if (typeof featured != "undefined") {
        return (
            <>
                <ScrollableSection classNames="featured-post-container">
                    <div className="featured-post-wrapper">
                        <article
                            className={`card card--featured ${featured.tags.nodes[0].name} `}
                        >
                            <div className="card__wrapper">
                                <figure className="card__img">
                                    {featured.featuredImage && (
                                        <img
                                            src={
                                                featured.featuredImage.node
                                                    .sourceURL
                                            }
                                            alt={featured.title}
                                            srcSet={
                                                featured.featuredImage.node
                                                    .srcSet
                                            }
                                        />
                                    )}
                                    <span className="card__cat">
                                        {featured.tags.nodes[0].name}
                                    </span>
                                </figure>
                                <div className="card__content">
                                    <p className="card__date small-text">
                                        {moment(featured.date).format(
                                            "MMMM Do YYYY"
                                        )}
                                    </p>
                                    <h4 className="card__title">
                                        {featured.title}
                                    </h4>
                                    <p
                                        className="card__excerpt"
                                        dangerouslySetInnerHTML={{
                                            __html: featured.excerpt,
                                        }}
                                    />
                                    <Button
                                        modifier="primary"
                                        text="read more"
                                        link={`/${featured.slug}`}
                                    />
                                </div>
                            </div>
                            <Link
                                to={`/${featured.slug}`}
                                className="card__full-link"
                            />
                        </article>
                    </div>
                </ScrollableSection>
            </>
        )
    } else {
        return null
    }

    // const formattedDate = moment(featured.date).format("MMMM Do YYYY")
    // return (
    //     <>
    //         <ScrollableSection classNames="featured-post-container">
    //             <div className="featured-post-wrapper">
    //                 <article
    //                     className={`card card--featured ${featured.tags.nodes[0].name} `}
    //                 >
    //                     <div className="card__wrapper">
    //                         <figure className="card__img">
    //                             <img
    //                                 src={featured.featuredImage.sourceURL}
    //                                 alt={featured.title}
    //                                 srcSet={featured.featuredImage.srcSet}
    //                             />
    //                             <span className="card__cat">
    //                                 {featured.tags.nodes[0].name}
    //                             </span>
    //                         </figure>
    //                         <div className="card__content">
    //                             <p className="card__date small-text">
    //                                 {moment(featured.date).format(
    //                                     "MMMM Do YYYY"
    //                                 )}
    //                             </p>
    //                             <h4 className="card__title">
    //                                 {featured.title}
    //                             </h4>
    //                             <p
    //                                 className="card__excerpt"
    //                                 dangerouslySetInnerHTML={{
    //                                     __html: featured.excerpt,
    //                                 }}
    //                             />
    //                             <Button
    //                                 modifier="primary"
    //                                 text="read more"
    //                                 link={`/${featured.slug}`}
    //                             />
    //                         </div>
    //                     </div>
    //                     <Link
    //                         to={`/${featured.slug}`}
    //                         className="card__full-link"
    //                     />
    //                 </article>
    //             </div>
    //         </ScrollableSection>
    //     </>
    // )
}

export default FeaturedPost
