import React from "react"

const TimelineNumber = props => {
    const index = props.index
    const current = props.current
    const year = props.year
    let classNames = "timeline-dates__year"

    if (current === index) classNames += " timeline-dates__year--current"

    return (
        <span
            className={classNames}
            onClick={props.handleClick}
            onKeyDown={props.handleClick}
            data-index={index}
            role="button"
            tabIndex={0}
        >
            {year}
        </span>
    )
}

export default TimelineNumber
