import React from "react"
import { useStaticQuery } from "gatsby"
import ScrollableSection from "../scrollable-section"
import EmployeeProfile from "./employeeProfile"
import { graphql } from "gatsby"

const TEAM_QUERY = graphql`
    query TeamQuery {
        wpgraphql {
            teamMembers(where: { categoryNotIn: "8" }) {
                nodes {
                    teamMemberId
                    teamMember {
                        name
                        position
                        biography
                        image {
                            sourceUrl
                            srcSet
                        }
                    }
                }
            }
        }
    }
`

const OurTeam = () => {
    const {
        wpgraphql: {
            teamMembers: { nodes },
        },
    } = useStaticQuery(TEAM_QUERY)

    return (
        <ScrollableSection classNames="our-team">
            <h4 className="our-team__heading">Meet Our Team</h4>
            {nodes.map(member => (
                <EmployeeProfile
                    key={member.teamMemberId}
                    member={member.teamMember}
                />
            ))}
        </ScrollableSection>
    )
}

export default OurTeam
