import React, { Component } from "react"
import Plus from "../../assets/svg/plus.svg"

class AwardCard extends Component {
    constructor(props) {
        super()
        this.copyContainerRef = React.createRef()
        this.copyHeightRef = React.createRef()
        this.state = {
            showMenu: false,
        }
    }

    openAccordion = e => {
        if (!this.state.showMenu) {
            this.copyContainerRef.current.style.maxHeight =
                this.copyHeightRef.current.scrollHeight + "px"
        } else {
            if (this.props.award.featuredImage != null) {
                this.copyContainerRef.current.style.maxHeight = "120px"
            } else {
                this.copyContainerRef.current.style.maxHeight = "0px"
            }
        }
    }

    toggleMenu = event => {
        event.preventDefault()

        this.setState({
            showMenu: !this.state.showMenu,
        })

        this.openAccordion()
    }

    render() {
        const { featuredImage, title, content } = this.props.award
        const btnText = this.state.showMenu ? `close` : `more`
        const btnClass = this.state.showMenu ? `opened` : `closed`

        return (
            <div className={`award ${featuredImage && "laurels"}`}>
                <div className="award__wrapper">
                    {featuredImage && (
                        <figure className="award__image">
                            <img
                                src={featuredImage.node.sourceUrl}
                                srcSet={featuredImage.node.srcSet}
                                alt={title}
                            />
                        </figure>
                    )}
                    <div className="award__copy-container">
                        <h4 className="award__title">{title}</h4>
                        <div className="award__copy">
                            <div
                                className={`award__copy-inner`}
                                ref={this.copyContainerRef}
                            >
                                <div
                                    className="award__copy-wrapper"
                                    ref={this.copyHeightRef}
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className={`award__btn ${btnClass}`}
                    onClick={this.toggleMenu}
                >
                    <span>{btnText}</span> <Plus />
                </button>
            </div>
        )
    }
}

export default AwardCard
