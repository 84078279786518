import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InteriorHero from "../components/interior-hero"
import WhySection from "../components/whySection"

const WHY_PAGE_QUERY = graphql`
    query WhyQuery {
        wpgraphql {
            pageBy(uri: "why-urban-design-matters") {
                interiorHero {
                    interiorHero {
                        copy
                        headline
                        subHeadline
                        image {
                            srcSet
                            sourceUrl
                        }
                    }
                }
            }
            whyUrbanDesignMatters {
                nodes {
                    id
                    whyUrbanDesignMatters {
                        headline
                        copy
                        backgroundText
                        quote
                        image {
                            sourceUrl
                            srcSet
                        }
                    }
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "OBD_line-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const Why = () => {
    const {
        wpgraphql: {
            pageBy: {
                interiorHero: {
                    interiorHero: { copy, headline, subHeadline, image },
                },
            },
            whyUrbanDesignMatters: { nodes: whys },
        },
        placeholderImage,
    } = useStaticQuery(WHY_PAGE_QUERY)

    return (
        <>
            <InteriorHero
                bgImage={placeholderImage.childImageSharp.fluid}
                subHeadline={subHeadline}
                headline={headline}
                image={image}
                copy={copy}
            />
            {whys.map(why => (
                <WhySection
                    key={why.id}
                    why={why.whyUrbanDesignMatters}
                    bgImage={placeholderImage.childImageSharp.fluid}
                />
            ))}
        </>
    )
}

export default Why
