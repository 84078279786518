import React, { Component } from "react"
import ScrollableSection from "./scrollable-section"
import Plus from "../assets/svg/plus.svg"

class AccordianSection extends Component {
    constructor(props) {
        super()
        this.copyContainerRef = React.createRef()
        this.copyHeightRef = React.createRef()
        this.state = {
            showMenu: false,
        }
    }

    openAccordion = e => {
        if (!this.state.showMenu) {
            this.copyContainerRef.current.style.maxHeight =
                this.copyHeightRef.current.scrollHeight + "px"
        } else {
            this.copyContainerRef.current.style.maxHeight = "0px"
        }
    }

    toggleMenu = event => {
        event.preventDefault()

        this.setState({
            showMenu: !this.state.showMenu,
        })

        this.openAccordion()
    }

    render() {
        const btnText = this.state.showMenu ? `close list` : `view list`
        const btnClass = this.state.showMenu ? `opened` : `closed`

        return (
            <ScrollableSection classNames="our-team">
                <div className="accordian__heading">
                    <h4 className="our-team__heading">{this.props.heading}</h4>
                    <button
                        className={`accordian__btn ${btnClass}`}
                        onClick={this.toggleMenu}
                    >
                        <span>{btnText}</span> <Plus />
                    </button>
                </div>
                <div className="accordian__container">
                    <div
                        className="accordian__inner"
                        ref={this.copyContainerRef}
                    >
                        <div
                            className="accordian__wrapper"
                            ref={this.copyHeightRef}
                        >
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </ScrollableSection>
        )
    }
}

export default AccordianSection
