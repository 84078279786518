import React from "react"
import { useStaticQuery } from "gatsby"
import ScrollableSection from "../scrollable-section"
import AwardCard from "./awardCard"
import { graphql } from "gatsby"

const AWARD_QUERY = graphql`
    query AwardQuery {
        wpgraphql {
            obdAwards {
                nodes {
                    featuredImage {
                        node {
                            sourceUrl
                            srcSet
                        }
                    }
                    title
                    content
                    id
                }
            }
        }
    }
`

const Awards = () => {
    const {
        wpgraphql: {
            obdAwards: { nodes: awards },
        },
    } = useStaticQuery(AWARD_QUERY)

    return (
        <ScrollableSection classNames="our-team our-team--director awards">
            <h4 className="our-team__heading">Omaha by Design Awards</h4>
            {awards.map(award => (
                <AwardCard key={award.id} award={award} />
            ))}
        </ScrollableSection>
    )
}

export default Awards
