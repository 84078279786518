import React, { Component } from "react"
import Plus from "../../assets/svg/plus.svg"

class EmployeeProfile extends Component {
    constructor(props) {
        super()

        this.bioContainerRef = React.createRef()
        this.bioCopyRef = React.createRef()
        this.moreRef = React.createRef()
        this.closeRef = React.createRef()
        this.state = {
            showMenu: false,
        }
    }

    openAccordion = e => {
        const parent = this.bioContainerRef.current.parentElement
        const container = this.bioContainerRef.current
        const copy = this.bioCopyRef.current

        if (!parent.classList.contains("member__bio_copy--open")) {
            container.style.maxHeight = copy.scrollHeight + "px"
            this.closeRef.current.classList.add("show")
            this.moreRef.current.classList.remove("show")
        } else {
            container.style.maxHeight = "100px"
            this.closeRef.current.classList.remove("show")
            this.moreRef.current.classList.add("show")
        }
    }

    showMenu = event => {
        event.preventDefault()
        this.openAccordion()

        this.setState({
            showMenu: true,
        })
    }

    closeMenu = event => {
        event.preventDefault()
        this.openAccordion()

        this.setState({
            showMenu: false,
        })
    }

    render() {
        const { name, position, biography, image } = this.props.member

        return (
            <div className="member">
                <div className="member__wrapper">
                    <figure className="member__image">
                        <img
                            src={image.sourceUrl}
                            srcSet={image.srcSet}
                            alt={name}
                        />
                    </figure>
                    <div className="member__bio">
                        <h4 onClick={this.closeMenu} onKeyDown={this.closeMenu}>
                            {name}
                        </h4>
                        <p className="small-text">{position}</p>
                        <div
                            className={`member__bio-copy ${this.state
                                .showMenu && "member__bio_copy--open"}`}
                        >
                            <div
                                ref={this.bioContainerRef}
                                className="member__bio-copy-inner"
                            >
                                <div
                                    className="member__bio-wrapper"
                                    ref={this.bioCopyRef}
                                    dangerouslySetInnerHTML={{
                                        __html: biography,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="member-mobile">
                            <button
                                ref={this.moreRef}
                                className="member-mobile__btn member-mobile__btn--more show"
                                onClick={this.showMenu}
                            >
                                <span>more</span> <Plus />
                            </button>
                            <button
                                ref={this.closeRef}
                                className="member-mobile__btn member-mobile__btn--close"
                                onClick={this.closeMenu}
                            >
                                <span>close</span> <Plus />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmployeeProfile
