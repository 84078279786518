import React, { useEffect } from "react"
import Plus from "../../assets/svg/plus.svg"

const TimelineSlide = props => {
    useEffect(() => {})

    const copyRef = React.createRef()

    const showCopy = event => {
        copyRef.current.classList.add("open")
    }

    const hideCopy = event => {
        copyRef.current.classList.remove("open")
    }

    const { headline, copy, year, mainImage, secondaryImage } = props.slide
    const index = props.index
    const current = props.current
    let classNames = "timeline-slide"

    if (current === index) classNames += " timeline-slide--current"
    else if (current - 1 === index) classNames += " timeline-slide--previous"
    else if (current + 1 === index) classNames += " timeline-slide--next"

    return (
        <div className={classNames} data-index={index}>
            <div className="timeline-slide__wrapper">
                <figure className="timeline-slide__image timeline-slide__image--main">
                    <img
                        src={mainImage.sourceUrl}
                        srcSet={mainImage.srcSet}
                        alt={headline}
                    />
                </figure>
                <figure className="timeline-slide__image timeline-slide__image--secondary">
                    <img
                        src={secondaryImage.sourceUrl}
                        srcSet={secondaryImage.srcSet}
                        alt={headline}
                    />
                </figure>

                <h2 className="timeline-slide__headline">
                    {headline}
                    <span className="underline"></span>
                </h2>
                <button
                    className="timeline-slide__btn timeline-slide__btn--more"
                    onClick={showCopy}
                >
                    more <Plus />
                </button>
                <div className="timeline-slide__copy" ref={copyRef}>
                    <p>{copy}</p>
                    <button
                        className="timeline-slide__btn timeline-slide__btn--close"
                        onClick={hideCopy}
                    >
                        close <Plus />
                    </button>
                </div>

                <p className="vertical-text">{year}</p>
            </div>
        </div>
    )
}

export default TimelineSlide
