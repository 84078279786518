import React from "react"

const SecondaryMember = ({ name, position, company }) => {
    const isPosition = position
    return (
        <div className="secondary-member">
            {isPosition ? 
                <p className="secondary-member__position small-text">
                    {position}
                </p> : <span>&nbsp;</span>
            
        }
            <h4 className="secondary-member__name">{name}</h4>
            <p className="secondary-member__company">{company}</p>
        </div>
    )
}

export default SecondaryMember
