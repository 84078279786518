import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InteriorHero from "../components/interior-hero"
import Priorities from "../components/our-priorities"
import QuoteBlock from "../components/quote-block"
import Timeline from "../components/timeline"
import OurTeam from "../components/our-team"
import ScrollableSection from "../components/scrollable-section"
import SecondaryMember from "../components/secondaryMember"
import EmployeeProfile from "../components/our-team/employeeProfile"
import Awards from "../components/awards/index"
import AccordianSection from "../components/accordianSection"

const ABOUT_PAGE_QUERY = graphql`
    query AboutUsQuery {
        wpgraphql {
            pageBy(uri: "about-us") {
                interiorHero {
                    interiorHero {
                        copy
                        headline
                        subHeadline
                        image {
                            srcSet
                            sourceUrl
                        }
                    }
                }
                quoteBlock {
                    quote
                    reference
                }
            }
            boardMembers(first: 60) {
                nodes {
                    title
                    boardMember {
                        company
                        position
                    }
                    id
                }
            }
            teamMembers(where: { title: "Connie Spellman" }) {
                nodes {
                    title
                    id
                    teamMember {
                        position
                        name
                        biography
                        image {
                            sourceUrl
                            srcSet
                        }
                    }
                }
            }
            advisoryMembers(first: 60) {
                nodes {
                    councilMember {
                        position
                        name
                        company
                    }
                    id
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "OBD_line-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const AboutUs = () => {
    const {
        wpgraphql: {
            pageBy: {
                interiorHero: {
                    interiorHero: { copy, headline, subHeadline, image },
                },
                quoteBlock,
            },
            boardMembers: { nodes: boardMembers },
            teamMembers: { nodes: director },
            advisoryMembers: { nodes: advisoryMembers },
        },
        placeholderImage,
    } = useStaticQuery(ABOUT_PAGE_QUERY)

    return (
        <>
            <InteriorHero
                bgImage={placeholderImage.childImageSharp.fluid}
                subHeadline={subHeadline}
                headline={headline}
                image={image}
                copy={copy}
            />
            <Priorities />
            <QuoteBlock
                quote={quoteBlock.quote}
                name={quoteBlock.reference}
                bgImage={placeholderImage.childImageSharp.fluid}
                bgColor="red"
            />
            <Timeline />
            <OurTeam />
            <ScrollableSection classNames="our-team our-team--board">
                <h4 className="our-team__heading">Board of Directors</h4>
                <div className="secondary-member-wrapper">
                    {boardMembers.map(member => (
                        <SecondaryMember
                            name={member.title}
                            position={member.boardMember.position}
                            company={member.boardMember.company}
                            key={member.id}
                        />
                    ))}
                </div>
            </ScrollableSection>
            <ScrollableSection classNames="our-team our-team--director">
                <h4 className="our-team__heading">
                    {director[0].teamMember.position}, 2001 - 2015
                </h4>
                <EmployeeProfile
                    key={director.id}
                    member={director[0].teamMember}
                />
            </ScrollableSection>
            <AccordianSection heading="Advisory Council">
                <div className="secondary-member-wrapper accordian-container">
                    {advisoryMembers.map(member => (
                        <SecondaryMember
                            name={member.councilMember.name}
                            position={member.councilMember.position}
                            company={member.councilMember.company}
                            key={member.id}
                        />
                    ))}
                </div>
            </AccordianSection>
            <Awards />
        </>
    )
}

export default AboutUs
