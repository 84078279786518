import React from "react"
import NewsIcon from "../assets/svg/filter-icon--news.svg"
import EventsIcon from "../assets/svg/filter-icon--events.svg"
import AllIcon from "../assets/svg/filter-icon--all.svg"

//Acceptable Modifiers: primary, special

const PostFilters = ({ filterAction }) => {
    const wrapperRef = React.createRef()

    const switchClass = event => {
        const btns = [
            ...wrapperRef.current.querySelectorAll(".impact-filters__icon"),
        ]
        btns.forEach(btn => btn.classList.remove("active"))
        event.target.classList.add("active")
    }

    const onClick = event => {
        switchClass(event)
        filterAction(event)
    }

    return (
        <section className="impact-filters">
            <p className="small-text">Filter Areas of Impact</p>
            <div className="impact-filters__wrapper" ref={wrapperRef}>
                <button
                    className="impact-filters__icon"
                    onClick={onClick}
                    data-filter=".news"
                >
                    <NewsIcon />
                    <p>News</p>
                </button>
                <button
                    className="impact-filters__icon"
                    onClick={onClick}
                    data-filter=".event"
                >
                    <EventsIcon />
                    <p>Events</p>
                </button>
                <button
                    className="impact-filters__icon active"
                    onClick={onClick}
                    data-filter="*"
                >
                    <AllIcon />
                    <p>All</p>
                </button>
            </div>
        </section>
    )
}

export default PostFilters
