import React from "react"

const bannerAffordable = ({content}) => {
    const backgroundImage = content.backgroundImage.mediaItemUrl;
    const backgroundImageAlt = content.backgroundImage.altText;
    const subtitle = content.subtitle;
    const button = content.button;
    const switchContent = content.switchContent;
    const highlightText = content.highlightText;
    const images = content.images;
    let image2 = false;
    let image2AltText = false;

    if(content.image2) {
        image2 = content.image2.mediaItemUrl;
        image2AltText = content.image2.altText;
    }
    const title = content.title;
    const titleSmall = content.titleSmall;
    const text = content.text;
    if(backgroundImage || subtitle || button || highlightText || images || image2 || title || titleSmall || text) {
        return (
            <section className={`banner-affordable ${switchContent ? 'switch_image' : ''} ${!title && !titleSmall && !highlightText && !text && !switchContent ? 'open_omaha' : '' } ${title && !titleSmall && !highlightText && !switchContent ? 'open_omaha laures_awards' : '' }  `}>
                <div className="background-image">
                    {
                        backgroundImage && <img src={backgroundImage} alt={backgroundImageAlt} className="img-fluid" />
                    }
                    
                </div>
                <div className={`top-area ${title && !titleSmall && !highlightText && !switchContent ? 'smaller_width' : '' }`}>
                    {
                        images && 
                        <div className="image-left">
                            {
                                (images && images.length == 1) && <img src={images[0].image.mediaItemUrl} alt={images[0].image.altText} className="img-fluid" />
                            }
                            {
                                (images && images.length > 1) && 
                                <div className="slider-image">
                                    {
                                        images.map((item, index) => {
                                            return (
                                                <div className="slider-image-box">
                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    <div className="text-box-right">
                        <div className="top-title">
                            {
                                image2 && 
                                <div className="img-left">
                                    { image2 && <img src={image2} alt={image2AltText} className="img-fluid" /> }
                                </div>
                            }
                            <div className="title-right">
                                {title && <h1>{title}</h1>}
                                {titleSmall && <h2>{titleSmall}</h2>}
                            </div>
                        </div>
                        {subtitle && <h3>{subtitle}</h3> }
                        {button && <a href={button.url} target={button.target} className="btn">{button.title}</a>}
                        
                    </div>
                </div>
                <div className="text-area">
                    <div className="text_html" dangerouslySetInnerHTML={{ __html: text }}>
                    </div>
                    <p className="highlight" dangerouslySetInnerHTML={{ __html: highlightText }}>
                    </p>
                </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default bannerAffordable
