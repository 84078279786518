import React from "react"
import ScrollableSection from "./scrollable-section"
import Arrow from "../assets/svg/arrow-right.svg"

const scrollTo = event => {
    event.preventDefault()
    const element = document.querySelector("#impactFilters")
    window.scrollTo({
        left: 0,
        top: element.offsetTop,
    })
}

const ImpactSection = props => {
    const { title, content, featuredImage } = props.area

    return (
        <ScrollableSection classNames="impact">
            <div className="impact__wrapper">
                <div className="impact__content">
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    <a
                        href="#impactFilters"
                        className="btn-primary"
                        onClick={event => {
                            scrollTo(event)
                        }}
                    >
                        view all <Arrow />
                    </a>
                </div>
                <figure className="impact__image">
                    <img
                        src={featuredImage.node.sourceUrl}
                        srcSet={featuredImage.node.srcSet}
                        sizes="(min-width: 990px) 50vw, 100vw"
                        alt={title}
                    />
                </figure>
            </div>
        </ScrollableSection>
    )
}

export default ImpactSection
