import React from "react"
import ScrollableSection from "../components/scrollable-section"
import Img from "gatsby-image"

//BG Colors are either red or gray

const QuoteBlock = ({ quote, name, bgImage, bgColor }) => {
    return (
        <ScrollableSection classNames={`quote-block quote-block--${bgColor}`}>
            <Img
                fluid={bgImage}
                style={{
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    width: `100%`,
                    height: `100%`,
                    opacity: `0.04`,
                }}
            />
            <div className="quote-block__wrapper">
                <div className="quote-card">
                    <h3 dangerouslySetInnerHTML={{ __html: quote }}></h3>
                    <p className="quote-card__name">-{name}</p>
                </div>
            </div>
        </ScrollableSection>
    )
}

export default QuoteBlock
